import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import SalesListViewLayout from "./SalesListView";
import SalesDetailLayout from "./detailView/SalesDetailLayout";
import SaleDetailsContainer from "./components/saleDetails/SaleDetailsContainer";
// state
import SalesViewProvider from "./SalesViewProvider";
import SalesListProvider from "./SalesListView/SalesListProvider";
import SalesDetailProvider from "./detailView/SalesDetailProvider";
import TableProvider from "./SalesListView/SalesListViewTable/TableProvider";
// utils
import { routeBase } from "./SalesListView/listViewConfig";
// interfaces
import { SalesSubview } from "./enums";

/** ### `Sales` view */
const SalesRouter: FC = () => (
  <Routes>
    {/* Detail-view routes */}
    <Route
      path={`/${SalesSubview.enum.applications}/:id/*`} // @todo use varname appRecId
      element={<SaleDetailsContainer />}
    />
    <Route
      path={`/${SalesSubview.enum.postedSales}/:id/*`} // @todo use varname appRecId
      element={<SaleDetailsContainer />}
    />

    {/* @todo in a future ticket: migrate above detail-routes to consistent layout set by SalesDetailLayout */}
    <Route path="/:subview/:appRecId/*" element={<SalesDetailLayout />} />

    {/* List-view routes */}
    <Route path="/:subview/" element={<SalesListViewLayout />} />

    {/* Fallback - defaults to Applications */}
    <Route
      path="/"
      element={<Navigate to={`/${routeBase}/${SalesSubview.enum.applications}`} replace={true} />}
    />
    <Route
      path="*"
      // @todo redirect to an error page for the catchall/splat route
      // element={<Navigate to={`/${routeBase}/${SalesSubview.enum.notFound}`} replace={true} />}
      element={<Navigate to={`/${routeBase}/${SalesSubview.enum.applications}`} replace={true} />}
    />
  </Routes>
);

const Sales: FC = () => (
  <SalesViewProvider>
    <SalesListProvider>
      <TableProvider>
        {/* @todo remove when sidebar is handled */}
        <SalesDetailProvider>
          <SalesRouter />
        </SalesDetailProvider>
      </TableProvider>
    </SalesListProvider>
  </SalesViewProvider>
);

export default Sales;
