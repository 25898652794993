import { FC } from "react";
// components
import WsFormBody from "../layoutComponents/WsFormBody";
import GeneralInfoForm from "./GeneralInfoForm";
import WsCompanyInfoForm from "./WsCompanyInfoForm";
import WholesaleCompanyFormProvider from "./WsCompanyModal/WholesaleCompanyFormProvider";
// state
import { useWholesaleViewCtx } from "../WholesaleViewProvider";

/** ### Wholesale Management Tab Layout */
const WholesaleMgmtTab: FC = () => {
  const isFormLoaded = useWholesaleViewCtx((s) => s.isFormLoaded);
  const isAddlDataLoaded = useWholesaleViewCtx((s) => s.formAddlData !== null);

  if (!isAddlDataLoaded || !isFormLoaded) return <></>;

  return (
    <WholesaleCompanyFormProvider>
      <WsFormBody>
        <GeneralInfoForm />
        <WsCompanyInfoForm />
      </WsFormBody>
    </WholesaleCompanyFormProvider>
  );
};

export default WholesaleMgmtTab;
