import { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from "react";
import { useMatch, useLocation, useNavigate } from "react-router";
import { z } from "zod";
// state
import { useAppDispatch } from "@/store/store";
import { useAuthSelector } from "@/features/auth/authSlice";
import { getAccountInformation } from "@/features/Accounts/accountActionCreators";
import { getSaleData } from "@/features/Sales/salesActionCreator";
// services
import { accountsService } from "@/services/accountsService";
import { paymentService } from "@/services/paymentService";
import { collectionsService } from "@/services/collectionsService";
// validation
import useValidatedForm from "@/utils/forms/useValidatedForm";
import { BasePmtFormSchema, mapReqDataToForm } from "./formValidation";
import { paymentFormCfg } from "./formConfig";
import { PmtReq, PmtReqMisc } from "./reqValidation";
import { ZCardProcessorName, cardProcessorMap } from "@/enums/payment";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useReq from "@/utils/useReq";
import { getCanWaiveFee, getCpiDueDate, getPaymentProviders } from "@/utils/helpers/payment";
import { getRouteParamNum } from "@/utils/routing/formatting";
import {
  calcCcAch,
  calcConvFee,
  getConvenienceFeeByProviderMisc,
} from "./formCalculations/readonlyValues";
import validateReqBody from "@/utils/forms/validateReqBody";
import { formToReqBody, formToReqBodyMisc, getEnabledPmtMethods } from "./pmtFormProviderUtils";
import { useNavUp } from "@/utils/routing/useNavUpEvent";
// interfaces
import { SavedPmtMethod } from "@/interfaces/CreditCard";
import { ColTypeCode } from "@/enums/general";
import { AccountTabRoutes } from "../../interfaces";
import { SalesSubview } from "@/features/Sales/enums";

const newProviderMap = () =>
  new Map<ZCardProcessorName, SavedPmtMethod[]>(ZCardProcessorName.options.map((p) => [p, []]));

const useCtxState = (handleSuccess: (() => void) | undefined, isPrinOnly: boolean = false) => {
  const navigate = useNavigate();
  const navUp = useNavUp();
  const location = useLocation().pathname;
  const dispatch = useAppDispatch();

  const acctFormMatch = useMatch({ path: `/accounts/:colRecId/:subview/*` });
  const isPmtOnly = acctFormMatch?.params.subview === AccountTabRoutes.PaymentOnly; // @note "/accounts/:colRecId/payment-only"
  const isMiscPmt = acctFormMatch?.params.colRecId === "miscPayment"; // @note Misc. Pmts route = "/accounts/miscPayment/new"
  const colRecIdParam = getRouteParamNum(acctFormMatch?.params.colRecId); // This will be `null` for misc-pmt route

  const saleFormPath = "/sales/:subview/:appRecId"; //saleFormMatch?.params.subview
  const saleFormMatch = useMatch({ path: `${saleFormPath}/*` });
  const appRecIdParam = getRouteParamNum(saleFormMatch?.params.appRecId);

  const isDownPmt =
    saleFormMatch?.params.subview === SalesSubview.enum.postedSales &&
    !!saleFormMatch?.params["*"]?.includes("sale-management/down-payment");
  const isSalesWsView = saleFormMatch?.params.subview === SalesSubview.enum.wholesales;
  const authState = useAuthSelector((s) => s);
  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);
  const employeeRecId = useAuthSelector((s) => s.userId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [continueSubmitting, setContinueSubmitting] = useState(false);
  const [isOpenEdgeModalOpen, setIsOpenEdgeModalOpen] = useState(false);
  const [isCliqModalOpen, setIsCliqModalOpen] = useState(false);
  /** This value controls the `<RepayModal />` state */
  const [repayIframeUrl, setRepayIframeUrl] = useState("");
  const [paymentLogRecId, setPaymentLogRecId] = useState<number | null>(null);
  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
  const [showPendingRewriteModal, setShowPendingRewriteModal] = useState(false);
  const [cancellingRewrite, setCancellingRewrite] = useState(false);

  /**
   * @note this state is only used for down payments - appRecId is used to get colRecId, which is used for multiple requests
   * @note May be able to remove - add `getPaymentDataByAppRecId`
   */
  const downPmtColRecId = useReq(async () => {
    const appliedColType: ColTypeCode | null = isSalesWsView ? "WS" : isDownPmt ? "CD" : null;
    if (appliedColType && appRecIdParam) {
      return await collectionsService.getColRecId(appRecIdParam, appliedColType);
    } else return null;
  });
  const paymentProviders = useReq(async () => {
    // Misc. payment form
    if (isMiscPmt && compId) return await paymentService.getPaymentProvidersByCompany(compId);

    // Sales forms (Down payment/Wholesale)
    if ((isDownPmt || isSalesWsView) && downPmtColRecId.value)
      return await paymentService.getPaymentProviders(downPmtColRecId.value); // @note see comment r.e. replacement on `downPmtColRecId`

    // Regular/PrinOnly payment form
    if (colRecIdParam) return await paymentService.getPaymentProviders(colRecIdParam);
    return null;
  });
  const paymentData = useReq(async () => {
    // Not needed for misc. payment form
    if (isMiscPmt) return;

    // Sales forms (Down payment/Wholesale)
    if ((isDownPmt || isSalesWsView) && downPmtColRecId.value)
      return await paymentService.getPaymentDataByColRecId(downPmtColRecId.value);

    // Regular/PrinOnly payment form
    if (colRecIdParam) return await paymentService.getPaymentDataByColRecId(colRecIdParam);
    return null;
  });
  const validPmtMethods = useReq(async () => {
    if (!paymentData.value?.appRecId) return;
    const pmtMethodsMap = newProviderMap();
    // @note Do these reqs change across different payment form implementations? (i.e. appRecId vs colRecId)
    const res = await paymentService.getSavedPaymentMethods(paymentData.value.appRecId);

    res.forEach((pm) => {
      const processorVal = z
        .preprocess(
          (input) =>
            (input as string).toUpperCase() === ZCardProcessorName.enum.REPAY
              ? ZCardProcessorName.enum.REPAY
              : input,
          ZCardProcessorName
        )
        .safeParse(pm.cardProcessor);
      const isValidProcessor = processorVal.success;
      const isValidPmtMethodInfo = pm.fName && pm.lName && pm.mpdId && pm.last4;
      const isValidPmtMethod = isValidProcessor && isValidPmtMethodInfo && pm.isActive;

      if (!isValidPmtMethod) return;
      pmtMethodsMap.get(processorVal.data)!.push({ ...pm, cardProcessor: processorVal.data });
    });

    return pmtMethodsMap;
  });
  const employees = useReq(async () => {
    if (!compId) return;
    return await paymentService.getUsersByCompanyId(compId);
  });
  const miscCategories = useReq(async () => {
    if (orgId && isMiscPmt) return await paymentService.getMiscCategories(orgId);
    return null;
  });
  const isPendingRewrite = useReq(async () => {
    const appliedAppRecId = appRecIdParam || paymentData.value?.appRecId || null;
    if (!appliedAppRecId) return;
    return await accountsService.getIsPendingRewrite(appliedAppRecId);
  });

  const isWsPmt = paymentData.value?.colType === "WS";
  const providerInt = paymentProviders.value?.preferredPaymentProvider;
  const newProvider = providerInt ? cardProcessorMap.get(providerInt) : null;
  const canWaiveFee = getCanWaiveFee(newProvider as ZCardProcessorName, paymentProviders.value);

  const pmtFormCfg = useMemo(
    () =>
      paymentFormCfg({
        ...paymentData.value,
        canWaiveFee,
        enabledPmtProviders: getPaymentProviders(paymentProviders.value),
        validPmtMethods: validPmtMethods.value,
        isDownPmt,
        isWsPmt,
        isMiscPmt,
        isPrincipalOnly: isPrinOnly,
        paymentProviders: paymentProviders.value,
      }),
    [
      JSON.stringify(paymentData.value) +
        JSON.stringify(paymentProviders.value) +
        JSON.stringify(validPmtMethods.value),
    ]
  );

  const defaultValues = mapReqDataToForm(
    isPrinOnly,
    isDownPmt,
    isWsPmt,
    employeeRecId,
    paymentData.value,
    paymentProviders.value,
    validPmtMethods.value
  );
  const formState = useValidatedForm(BasePmtFormSchema, defaultValues, pmtFormCfg);
  const form = formState.form;

  // Loaders
  useEffect(() => {
    paymentProviders.load();
    paymentData.load();
  }, [colRecIdParam, downPmtColRecId.value]);
  useEffect(() => {
    downPmtColRecId.load();
  }, [appRecIdParam]);
  useEffect(() => {
    validPmtMethods.load();
    employees.load();
    miscCategories.load();
    // isPendingRewrite.load(); // @todo re-enable in followup commit
  }, [paymentData.value === null]);

  // Getters (Fetch-req)
  const areFetchReqsSuccessfulDefault =
    paymentProviders.value !== null &&
    paymentData.value !== null &&
    employees.value !== null &&
    validPmtMethods.value !== null;
  const areFetchReqsSuccessfulMisc =
    paymentProviders.value !== null && employees.value !== null && miscCategories.value !== null;
  const areFetchReqsSuccessfulDownPmt =
    paymentProviders.value !== null && employees.value !== null && miscCategories.value !== null;
  const areFetchReqsSuccessful =
    (isMiscPmt && areFetchReqsSuccessfulMisc) ||
    (isDownPmt && areFetchReqsSuccessfulDownPmt) ||
    areFetchReqsSuccessfulDefault;

  const isLoading =
    paymentProviders.isLoading ||
    paymentData.isLoading ||
    validPmtMethods.isLoading ||
    miscCategories.isLoading ||
    employees.isLoading;

  const enabledProviders = getPaymentProviders(paymentProviders.value);

  /** All available user-saved payment methods for:
   * - Active/selected payment provider - `ZCardProcessorName`
   * - Active/selected payment type
   */
  const enabledPmtMethods = getEnabledPmtMethods(
    paymentProviders.value,
    validPmtMethods.value,
    form
  );
  const selectedPmtMethod = enabledPmtMethods?.find((pm) => pm.recId === form.mpdRecId) ?? null;

  // Getters (form)
  const employee = employees.value?.find((emp) => emp.recId === form.employeeRecId);
  const cpiDueDate = getCpiDueDate(paymentData.value, form.cpiPaid);
  const { isCc, isAch, isCcOrAch } = calcCcAch(form);
  /** @deprecated - need to pass into external values */
  const convFeePmts = calcConvFee(form, paymentData.value);
  const convFeeMisc = getConvenienceFeeByProviderMisc(form, paymentProviders.value!);
  const convFee = isMiscPmt ? convFeeMisc : convFeePmts;

  const reqBodyValidation = validateReqBody(
    PmtReq,
    formToReqBody(
      {
        validatedForm: formState.validation,
        external: formState.config.external,
        calculated: formState.config.calculated,
      },
      selectedPmtMethod,
      employee,
      paymentData.value
    )
  );

  const reqBodyValidationMisc = validateReqBody(
    PmtReqMisc,
    formToReqBodyMisc({
      formValues: formState.validation.data,
      externalValues: formState.config.external,
      employee,
      selectedPmtMethod,
      authState,
      convFee,
    })
  );

  // Default payment form & down-payment form - build request body
  useEffect(() => {
    if (areFetchReqsSuccessful) {
      formState.resetToDefault(defaultValues, true);
    }
  }, [areFetchReqsSuccessful, isLoading, location]);

  const appliedHandleSuccess = async () => {
    setIsSubmitting(false);
    formState.resetToDefault(defaultValues, true);
    setIsOpenEdgeModalOpen(false);
    setIsCliqModalOpen(false);
    setRepayIframeUrl("");
    receiptUrl && window.open(receiptUrl, "_blank", "noopener,noreferrer");
    setReceiptUrl(null);

    if (handleSuccess) return handleSuccess();

    colRecIdParam && (await dispatch(getAccountInformation(colRecIdParam)));
    appRecIdParam && (await dispatch(getSaleData(appRecIdParam)));

    // @todo remove isPmtOnly case - already handled
    isPmtOnly ? navigate("/accounts/unpaidSales") : navUp();
  };
  useEffect(() => {
    if (receiptUrl && !isOpenEdgeModalOpen && !repayIframeUrl && !isCliqModalOpen) {
      appliedHandleSuccess();
    }
  }, [receiptUrl, isOpenEdgeModalOpen, repayIframeUrl, isCliqModalOpen]);

  return {
    handleSuccess: appliedHandleSuccess,

    // Fetch-req states
    paymentProviders,
    paymentData,
    downPmtColRecId,
    employees,
    miscCategories,
    isPendingRewrite,
    paymentLogRecId,
    setPaymentLogRecId,
    validPmtMethods: validPmtMethods.value,
    enabledPmtMethods,
    areFetchReqsSuccessful,
    areFetchReqsSuccessfulDefault,
    areFetchReqsSuccessfulMisc,
    isLoading,
    receiptUrl,
    setReceiptUrl,

    // Layout states
    isCliqModalOpen,
    setIsCliqModalOpen,
    isOpenEdgeModalOpen,
    setIsOpenEdgeModalOpen,
    repayIframeUrl,
    setRepayIframeUrl,
    showPendingRewriteModal,
    setShowPendingRewriteModal,
    cancellingRewrite,
    setCancellingRewrite,

    // Form/submit states
    formState,
    reqBodyValidation,
    reqBodyValidationMisc,
    isSubmitting,
    setIsSubmitting,
    continueSubmitting,
    setContinueSubmitting,

    // Getters
    selectedPmtMethod,
    employee,
    enabledProviders,
    cpiDueDate,
    /** @deprecated pull from `formState.config.calculated` */
    isCc,
    /** @deprecated pull from `formState.config.calculated` */
    isAch,
    /** @deprecated pull from `formState.config.calculated` */
    isCcOrAch,
    /** @deprecated pull from `formState.config.calculated` */
    convFee,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
export type PmtFormCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);
export const usePmtFormCtx = useCtxFactory(Ctx);

const PmtFormProvider: FC<
  PropsWithChildren & { handleSuccess?: () => void; isPrinOnly?: boolean }
> = ({ children, handleSuccess, isPrinOnly }) => (
  <Ctx.Provider value={useCtxState(handleSuccess, isPrinOnly)}>{children}</Ctx.Provider>
);

export default PmtFormProvider;
