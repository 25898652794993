import { FC, useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// components
import WsFormHeader from "./WsFormHeader";
import WholesaleMgmtTab from "./WholesaleMgmtTab";
import VehicleTab from "./VehicleTab";
import DocumentsTab from "./DocumentsTab";
import WsPaymentsView from "./WsPaymentsView";
import WsDetailHeader from "./WsDetailHeader";
// state
import { useSalesDetailCtx } from "../SalesDetailProvider";
import { useWholesaleViewCtx } from "./WholesaleViewProvider";

const WholesalesFormLayout: FC = () => {
  const appRecId = useSalesDetailCtx((s) => s.appRecId);
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);
  const wholesale = useWholesaleViewCtx((s) => s.wholesale);

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    setHeaderTitle(<WsDetailHeader />);

    // Cleanup: reset form states and tab state
    return () => {
      wholesale.setValue(null);
    };
  }, [appRecId]);

  return (
    <Grid container direction="column" flex={1} overflow="hidden" wrap="nowrap" p={2}>
      <WsFormHeader />
      <Outlet />
    </Grid>
  );
};

/** @note Place provider in right scope to prefetch data for 'Payment Summary' detail-view
 * On success:
 * 1. Nav to WS form view
 * 1. Load WS data
 * @todo add handleSuccess
 */
const WholesaleView: FC = () => (
  // <PmtFormProvider> @todo reenable - remove sale detail impl
    <Routes>
      <Route element={<WholesalesFormLayout />}>
        {/* Nav-tab routes */}
        <Route path="wholesale-management/*">
          <Route path="" element={<WholesaleMgmtTab />} />

          {/* "Take a payment" routes */}
          <Route path="payments/*" element={<WsPaymentsView />} />

          <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
        </Route>
        <Route path="vehicle" element={<VehicleTab />} />
        <Route path="documents" element={<DocumentsTab />} />
        {/* Fallbacks */}
        <Route path="/" element={<Navigate to="wholesale-management/" replace={true} />} />
        <Route path="*" element={<Navigate to="wholesale-management/" replace={true} />} />
      </Route>
    </Routes>
  // </PmtFormProvider>
);

export default WholesaleView;
