import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
// utils
// interfaces
import { useForm } from "react-hook-form";
import { SavedPmtMethodRes } from "@/interfaces";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { paymentService } from "@/services/paymentService";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SaveMpdForm } from "@/interfaces/payment";
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";

const defaultValues: SaveMpdForm = {
  recurringAuthRequestSentUtc: dayjs(),
  recurringIntervalRecIdPending: 0,
  amtPending: 0,
};

const useCtxState = () => {
  const appRecId = useAccountSelector((s) => getRouteParamNum(s.accountInformation?.appRecId));
  /** @deprecated replace with savedPaymentMethods.value */
  const [savedPaymentRows, setSavedPaymentRows] = useState<SavedPmtMethodRes[]>([]);
  const [activeCard, setActiveCard] = useState<SavedPmtMethodRes | null>(null);

  const savedPaymentForm = useForm<SaveMpdForm>({
    defaultValues,
    mode: "onChange",
  });

  const { reset, setValue } = savedPaymentForm;

  const savedPaymentMethods = useReq(async () => {
    if (!appRecId) return;
    return await paymentService.getSavedPaymentMethods(appRecId);
  });

  const loadSavedPayments = async () => {
    const savedPayments = savedPaymentMethods.value?.sort((a, b) => (a.recId! - b.recId!));
    if (!savedPayments || !Array.isArray(savedPayments)) {
      setSavedPaymentRows([]);
      setActiveCard(null);
      return;
    }
    setSavedPaymentRows(savedPayments);
    if (savedPayments.length > 0 && savedPayments[0]) {
      const currentActiveCard = savedPayments.find((s) => activeCard?.recId === s.recId) ?? savedPayments[0];
      setActiveCard(currentActiveCard);
      setValue("amtPending", currentActiveCard.amt ?? currentActiveCard.amtPending ?? 0);
      setValue(
        "recurringAuthRequestSentUtc",
        dayjs(
          currentActiveCard.startDatePending === ""
            ? currentActiveCard.startDate
            : currentActiveCard.startDatePending,
          "MM/DD/YYYY hh:mm:ssA"
        ) ?? dayjs()
      );
      setValue(
        "recurringIntervalRecIdPending",
        currentActiveCard.recurringIntervalRecId ??
          currentActiveCard.recurringIntervalRecIdPending ??
          0
      );
    } else {
      setActiveCard(null);
    }
    // Check if the number of cards exceeds 5
    if (savedPayments.length > 5) {
      toast.error(
        "You have reached the maximum number of saved cards. Please delete some before adding more."
      );
    }
  };

  useEffect(() => {
    savedPaymentMethods.load();
  }, [appRecId]);

  useEffect(() => {
    loadSavedPayments();
  }, [savedPaymentMethods.value]);

  return {
    savedPaymentMethods,
    savedPaymentRows,
    setSavedPaymentRows,
    activeCard,
    setActiveCard,
    savedPaymentForm,
  };
};

type ICtx = ReturnType<typeof useCtxState>;

const Ctx = createContext<ICtx | null>(null);
export type ISavedPaymentsCtx = ICtx;
export const useSavedPaymentsCtx = useCtxFactory(Ctx);

const SavedPaymentProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default SavedPaymentProvider;
