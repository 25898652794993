import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
// services
import { useSalesDetailCtx } from "@/features/Sales/detailView/SalesDetailProvider";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";

const useCtxState = () => {
  const appRecId = useSalesDetailCtx((s) => s.appRecId);
  const downPaymentsReq = useSalesDetailCtx((s) => s.downPayments);

  const [showDownPaymentForm, setShowDownPaymentForm] = useState(false);

  // (Re)Load down payments when table renders
  useEffect(() => {
    !showDownPaymentForm && downPaymentsReq.load();
    return () => downPaymentsReq.setValue(null);
  }, [appRecId, showDownPaymentForm]);

  return {
    // Layout states
    showDownPaymentForm,
    setShowDownPaymentForm,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export const useSaleMgmtCtx = useCtxFactory(Ctx);
const SaleMgmtProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SaleMgmtProvider;
