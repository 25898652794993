import { FC, PropsWithChildren, ReactNode, createContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
// services
import { inventoryService } from "@/services/inventoryService";
import { salesService } from "@/services/salesService";
// utils
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { SalesSubview } from "../enums";
import { getNumber } from "@/utils/helpers/general";

const useCtxState = () => {
  // @todo to be added
  const salesSubviewMatch = useMatch("/sales/:subview/:appRecId/*");
  /** Value from browser parameter `/sales/:subview` */
  const appRecId = getRouteParamNum(salesSubviewMatch?.params.appRecId);
  /** Value from browser parameter `/sales/:subview/:appRecId/` */
  const subview = SalesSubview.safeParse(salesSubviewMatch?.params.subview).data ?? null;

  /** @deprecated to be removed */
  const [headerTitle, setHeaderTitle] = useState<ReactNode>(null);

  /** @todo migrate req and value from redux - uncomment below statement (`useReq`) */
  // const saleData = useSalesSelector((s) => s.saleData);
  const saleData = useReq(async () => {
    if (!appRecId) return;
    return await salesService.getSale(appRecId);
  });
  const vehRecId = getNumber(saleData.value?.sale?.vehRecID);
  const suggestedSalesInfo = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getSuggestedSalesInfo(vehRecId);
  });
  const vehicleCost = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getVehicleCostDetails(vehRecId.toString());
  });

  /** @note Load/fetch must be manually called (i.e. downPayments.load()), as this is only used in few components. */
  const downPayments = useReq(async () => {
    if (!appRecId) return;
    return await salesService.getDownPaymentList(appRecId);
  });

  useEffect(() => {
    saleData.load();
  }, [appRecId]);
  useEffect(() => {
    suggestedSalesInfo.load();
    vehicleCost.load();
  }, [vehRecId]);

  return {
    /** @deprecated to be removed */
    headerTitle,
    /** @deprecated to be removed */
    setHeaderTitle,

    // Req. data states
    saleData,
    suggestedSalesInfo,
    vehicleCost,
    downPayments,

    // Router
    appRecId,
    subview,
  };
};

type ISalesDetailCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ISalesDetailCtx | null>(null);
export const useSalesDetailCtx = useCtxFactory(Ctx);

const SalesDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SalesDetailProvider;
