import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { resolvePath, useMatch, useNavigate, useParams } from "react-router-dom";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import PmtFormProvider, {
  usePmtFormCtx,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
import { useSalesDetailCtx } from "../SalesDetailProvider";
// services
import { salesService } from "@/services/salesService";
// utils
import { tabItems, tabMap, WholesaleFormTab } from "./default";
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { getNumber } from "@/utils/helpers/general";
import { zNumeric } from "@/utils/forms/fieldSchemas";
// interfaces
import {
  WsFormVehicle,
  IWsFormAddlDataRes,
  IWsSalespersonDb,
  WsFormAddlData,
  WsCompany,
} from "./interfaces";
import { WsFormErrors, WsForm, WsFormBase } from "./formInterfaces";

/** ###  */
const useCtxState = () => {
  const appRecId = useSalesDetailCtx((s) => s.appRecId);
  const subview = useSalesDetailCtx((s) => s.subview);
  const userRecId = getNumber(useAuthSelector((s) => s.userId)); // this may be used
  const employees = usePmtFormCtx((s) => s.employees.value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWsCompanyModalOpen, setIsWsCompanyModalOpen] = useState(false);
  // Form data
  const [wsFormDeprec, setWsFormDeprec] = useState<WsForm | null>(WsForm.fromRes(null));

  const [formAddlDataRes, setFormAddlDataRes_] = useState<WsFormAddlData | null>(null);
  // Validation
  const [wsFormErrors, setWsFormErrors] = useState<WsFormErrors>(new WsFormErrors());

  const wholesale = useReq(async () => {
    if (!appRecId) return null;
    return await salesService.getWholesaleForm(appRecId);
  });
  const wsAddlInfo = useReq(async () => await salesService.getWholesaleFormAddlLists());

  /** @deprecated remove */
  const setFormAddlDataRes = (
    wsFormAddlInfo: IWsFormAddlDataRes | null,
    salespeople?: IWsSalespersonDb[]
  ) => {
    if (wsFormAddlInfo === null) {
      setFormAddlDataRes_(null);
    } else {
      const wsFormAddlData = new WsFormAddlData(
        wsFormAddlInfo.companies,
        wsFormAddlInfo.vehicles,
        salespeople
      );
      setFormAddlDataRes_(wsFormAddlData);
    }
  };

  useEffect(() => {
    const initForm = wholesale.value ? WsForm.fromRes(wholesale.value) : null;
    setWsFormDeprec(initForm);
  }, [wholesale.value]);
  useEffect(() => {
    if (subview === "wholesales") {
      wholesale.load();
    }
  }, [appRecId, subview]);

  /** Set a single field on the active form */
  const setWsFormField = <T extends keyof WsFormBase = keyof WsFormBase>(
    field: T,
    value: WsForm[T]
  ) => {
    const updatedWsForm = WsForm.setField(field, value, wsFormDeprec!);
    setWsFormDeprec(updatedWsForm);
  };

  useEffect(() => {
    if (wsFormDeprec !== null && formAddlDataRes?.vehicles) {
      // Get vehicle
      const wsVehicle = WsFormVehicle.fromWsFormState(wsFormDeprec, formAddlDataRes.vehicles);
      // only override form values when vehicle exists and sale status is "pending"
      if (!wsVehicle || wsFormDeprec.saleStatus.toLowerCase() !== "pending") {
        return;
      }
      // Get vehicle-props for the form
      const { exceedsLim, exempt, notActual, milesIn, milesOut } = wsVehicle;
      const updatedWsFormWithVehicle = WsForm.new({
        ...wsFormDeprec,
        exceedsLim: exceedsLim || false,
        exempt: exempt || false,
        notActual: notActual || false,
        milesIn: milesIn || "0",
        milesOut: milesOut || "0",
      });

      setWsFormDeprec(updatedWsFormWithVehicle);
    }
  }, [wsFormDeprec?.vehRecId]);

  const setWsFormFieldError = (field: keyof WsFormErrors, msg: string) => {
    setWsFormErrors(WsFormErrors.setField(field, msg, wsFormErrors));
  };

  // Getters
  const isFormLoaded = wsFormDeprec !== null;
  // @todo Determine if more effective to use browser parameters
  const isFormEdit = zNumeric.safeParse(wholesale.value?.appRecId).success;

  /** Req.-data-state, including current form values for `company` and `vehicle` */
  const formAddlData = {
    ...formAddlDataRes,
    // Add the vehicle provided in the form to the lookup list
    vehicles: [
      ...(formAddlDataRes?.vehicles ?? []),
      WsFormVehicle.new({ ...wholesale.value, recId: wholesale.value?.vehRecId || undefined }),
    ],
    // Add the wholesale company provided in the form to the lookup list
    companies: [
      ...(formAddlDataRes?.companies ?? []),
      WsCompany.new({ ...wholesale.value, recId: wholesale.value?.wsBuyer || undefined }),
    ],
  };
  const selectedWsCompany = WsCompany.fromWsFormState(wsFormDeprec, formAddlData?.companies);
  const wsFormVehicle = WsFormVehicle.fromWsFormState(wsFormDeprec, formAddlData?.vehicles) ?? null;

  const selectedSalesperson = employees?.find((s) => s.recId === wsFormDeprec?.salesMan) || null;
  // default to the first item in the list
  const navTab = WholesaleFormTab.safeParse(useParams()["*"]).data ?? WholesaleFormTab.options[0];

  return {
    // Req data states
    wholesale,
    wsAddlInfo,

    // Layout states
    tabItems,
    tabMap,
    navTab,
    isModalOpen,
    setIsModalOpen,
    isWsCompanyModalOpen,
    setIsWsCompanyModalOpen,

    wsFormDeprec,
    setWsFormDeprec,
    setWsFormField,

    /** @deprecated remove - use `wsAddlInfo` */
    formAddlDataRes,
    /** @deprecated remove - use `wsAddlInfo` */
    setFormAddlDataRes,
    formAddlData,

    wsFormVehicle,
    selectedWsCompany,
    selectedSalesperson,

    wsFormErrors,
    setWsFormFieldError,

    // Getters
    isFormLoaded,
    isFormEdit,
  };
};
type ICtx = ReturnType<typeof useCtxState>;
export type IWholesaleFormCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);
export const useWholesaleViewCtx = useCtxFactory(Ctx);

/** @deprecated needs refactor */
const WholesaleViewProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const saleData = useSalesDetailCtx((s) => s.saleData);
  // Edge case: handle nav-up for wholesale form submit
  // 1. Confirm current path is wholesale form
  // 2. Programmatically Generate path
  const wsPmtFormRouteMatch = useMatch(
    "/sales/:subview/:appRecId/wholesale-management/payments/collect"
  );
  const wsPmtFormNavUpRoute = resolvePath({ pathname: `.${wsPmtFormRouteMatch?.pathname}/..` });

  /** 1. Reload sale detail; 2. Navigate up one level */
  const wsHandleSuccess = async () => {
    saleData.load();
    navigate(wsPmtFormNavUpRoute);
  };

  return (
    <PmtFormProvider handleSuccess={wsHandleSuccess}>
      <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
    </PmtFormProvider>
  );
};

export default WholesaleViewProvider;
